import { graphql } from 'gatsby';
import React from 'react';
import Button from '../components/Button/Button';
import Layout from '../components/Layout/Layout';
import { Link } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';
import sprite from '../../static/sprite.svg';
import './index.scss';
import VideoPlayer from '../components/VideoPlayer/VideoPlayer';
import { navigate } from 'gatsby';

const Home = ({ data }) => {
  function getImageForStory(storyImage) {
    const imageData = data.patientStoriesImages.nodes.find(
      ({ name }) => name.toLowerCase() === storyImage.toLowerCase()
    );
    return imageData.childImageSharp.gatsbyImageData;
  }

  return (
    <Layout>
      <section className="peoples-lives">
        <div className="peoples-lives__wrapper">
          <div className="peoples-lives__text">
            <h1 className="peoples-lives__heading">
              <span className="peoples-lives__prefix">
                {data.peopleText.childTextContentJson.people.headingPrefix}
              </span>
              <span className="heading-primary">
                {data.peopleText.childTextContentJson.people.headingText}
              </span>
              <span className="peoples-lives__prefix">
                {data.peopleText.childTextContentJson.people.headingPostfix}
              </span>
            </h1>
            {/* <p className="peoples-lives__description para">
              {data.peopleText.childTextContentJson.people.description}
            </p> */}
            <div className="peoples-lives__button-group">
              <Button
                label="Donate Now"
                center
                buttonStyle="SECONDARY"
                type="button"
                onClick={() => navigate('/donate')}
              />
              <Button
                label="Nominate a Patient"
                center
                buttonStyle="SECONDARY"
                type="button"
                onClick={() => navigate('/nominate-a-patient')}
              />
            </div>
            <div className="peoples-lives__button-group-2">
              <Button
                label="Join Us"
                center
                buttonStyle="SECONDARY"
                type="button"
                onClick={() => navigate('/join-us')}
              />
            </div>
          </div>
          <div className="peoples-lives__images">
            {/* <span className="peoples-lives__image"> */}
            <GatsbyImage
              image={data.peopleImages.nodes[0].childImageSharp.gatsbyImageData}
              alt="Illustration image"
              style={{
                width: '7.2rem',
                left: '51.3rem',
                top: '4rem',
                borderTopRightRadius: 0,
                borderBottomRightRadius: 0,
              }}
              className="peoples-lives__illustration"
            />
            <GatsbyImage
              image={data.peopleImages.nodes[1].childImageSharp.gatsbyImageData}
              alt="Illustration image"
              style={{
                width: '14.4rem',
                left: '34.2rem',
                top: '27.2rem',
              }}
              className="peoples-lives__illustration"
            />
            <GatsbyImage
              image={data.peopleImages.nodes[2].childImageSharp.gatsbyImageData}
              alt="Illustration image"
              style={{
                width: '14.4rem',
                left: '34.2rem',
                top: '0rem',
                borderTopLeftRadius: 0,
                borderTopRightRadius: 0,
              }}
              className="peoples-lives__illustration"
            />
            <GatsbyImage
              image={data.peopleImages.nodes[3].childImageSharp.gatsbyImageData}
              alt="Illustration image"
              style={{ width: '14.4rem', left: '17.1rem', top: '9.4rem' }}
              className="peoples-lives__illustration"
            />
            <GatsbyImage
              image={data.peopleImages.nodes[4].childImageSharp.gatsbyImageData}
              alt="Illustration image"
              style={{ width: '14.4rem', left: '0', top: '44.2rem' }}
              className="peoples-lives__illustration"
            />
            <GatsbyImage
              image={data.peopleImages.nodes[5].childImageSharp.gatsbyImageData}
              alt="Illustration image"
              style={{ width: '14.4rem', top: '-10.7rem', height: '52.5rem' }}
              className="peoples-lives__illustration"
            />
            {/* </span> */}
          </div>
        </div>
      </section>

      <section className="small-steps">
        <div className="small-steps__illustration">
          <span className="small-steps__icon-box">
            <svg className="small-steps__icon">
              <use href={`${sprite}#site-graphic`} />
            </svg>
          </span>
          <GatsbyImage
            image={data.smallStepsImage.childImageSharp.gatsbyImageData}
            alt="small steps illustration"
            class="small-steps__image"
          />
        </div>
        <div className="small-steps__text">
          <h2 className="small-steps__heading heading-secondary">
            {data.smallStepsText.childTextContentJson.smallSteps.heading}
          </h2>

          {data.smallStepsText.childTextContentJson.smallSteps.description.map(
            (para, index) => (
              <p key={index} className="para">
                {para}
              </p>
            )
          )}
        </div>
      </section>
      <section className="small-steps small-steps--right">
        <div className="small-steps__illustration">
          <span className="small-steps__icon-box">
            <svg className="small-steps__icon">
              <use href={`${sprite}#site-graphic`} />
            </svg>
          </span>
          <GatsbyImage
            image={data.joinUs.childImageSharp.gatsbyImageData}
            alt="small steps illustration"
            class="small-steps__image small-steps__image--right"
          />
        </div>
        <div className="small-steps__text small-steps__text--right">
          <h2 className="small-steps__heading heading-secondary">
            {data.smallStepsText.childTextContentJson.joinUs.heading}
          </h2>

          {data.smallStepsText.childTextContentJson.joinUs.description.map(
            (para, index) => (
              <p key={index} className="para">
                {para}
              </p>
            )
          )}
          <Link to="/join-us">
          <Button label="Join Us" />
        </Link>
        </div>
      </section>

      {/* <section className="join-us">
        <h2 className="join-us__heading heading-secondary">
          {data.smallStepsText.childTextContentJson.joinUs.heading}
        </h2>

        <Link to="/join-us">
          <Button label="Join Us" />
        </Link>
      </section> */}

      {/* <section className="patient-stories">
        <h2 className="patient-stories__heading heading-secondary">
          {data.patientStoriesText.childTextContentJson.patientStories.heading}
        </h2>
        <div className="patient-stories__top-story">
          <VideoPlayer
            heading={
              data.patientStoriesText.childTextContentJson.patientStories
                .topStory.heading
            }
            description={
              data.patientStoriesText.childTextContentJson.patientStories
                .topStory.description
            }
            imageData={
              data.patientStoriesTopImage.childImageSharp.gatsbyImageData
            }
            videoLink={
              data.patientStoriesText.childTextContentJson.patientStories
                .topStory.videoLink
            }
          />
        </div>
        <div className="patient-stories__other-stories">
          {data.patientStoriesText.childTextContentJson.patientStories.stories.map(
            story => (
              <VideoPlayer
                key={story.videoLink}
                heading={story.heading}
                description={story.description}
                size="SMALL"
                imageData={getImageForStory(story.image)}
                videoLink={story.videoLink}
              />
            )
          )}
        </div>
      </section> */}

      {/* <section className="overview">
        <div className="overview__wrapper">
          <div className="overview__text">
            <h2 className="overview__heading heading-secondary">
              {data.overviewText.childTextContentJson.overview.heading}
            </h2>

            {data.overviewText.childTextContentJson.overview.description.map(
              (para, index) => (
                <p key={index} className="overview__description para">
                  {para}
                </p>
              )
            )}

            
          </div>
          <div className="overview__illustrations">
            <GatsbyImage
              image={
                data.overviewImages.nodes[0].childImageSharp.gatsbyImageData
              }
              alt="Illustration image"
              style={{
                width: '12.2rem',
                left: '0',
                top: '7.1rem',
              }}
              className="overview__illustration"
            />
            <GatsbyImage
              image={
                data.overviewImages.nodes[1].childImageSharp.gatsbyImageData
              }
              alt="Illustration image"
              style={{
                width: '12.2rem',
                left: '0',
                top: '51.5rem',
              }}
              className="overview__illustration"
            />
            <GatsbyImage
              image={
                data.overviewImages.nodes[2].childImageSharp.gatsbyImageData
              }
              alt="Illustration image"
              style={{
                width: '12.2rem',
                left: '14.7rem',
                top: '14.2rem',
              }}
              className="overview__illustration"
            />
            <GatsbyImage
              image={
                data.overviewImages.nodes[3].childImageSharp.gatsbyImageData
              }
              alt="Illustration image"
              style={{ width: '12.2rem', left: '29.4rem', top: '7.1rem' }}
              className="overview__illustration"
            />
            <GatsbyImage
              image={
                data.overviewImages.nodes[4].childImageSharp.gatsbyImageData
              }
              alt="Illustration image"
              style={{ width: '12.2rem', top: '50.3rem', left: '29.4rem' }}
              className="overview__illustration"
            />
            <GatsbyImage
              image={
                data.overviewImages.nodes[5].childImageSharp.gatsbyImageData
              }
              alt="Illustration image"
              style={{ width: '12.2rem', top: '7.1rem', left: '44.1rem' }}
              className="overview__illustration"
            />
            <GatsbyImage
              image={
                data.overviewImages.nodes[6].childImageSharp.gatsbyImageData
              }
              alt="Illustration image"
              style={{ width: '12.2rem', top: '35.4rem', left: '44.1rem' }}
              className="overview__illustration"
            />
          </div>
        </div>
      </section> */}
    </Layout>
  );
};

export default Home;

export const query = graphql`
  query HomeQuery {
    peopleText: file(relativePath: { eq: "home.json" }) {
      childTextContentJson {
        people {
          description
          headingPrefix
          headingPostfix
          headingText
        }
      }
    }
    peopleImages: allFile(
      filter: { relativePath: { regex: "/Rectangle/" } }
      sort: { fields: name, order: ASC }
    ) {
      nodes {
        childImageSharp {
          gatsbyImageData
        }
      }
    }
    smallStepsImage: file(relativePath: { eq: "small-steps.png" }) {
      childImageSharp {
        gatsbyImageData
      }
    }
    joinUs: file(relativePath: { eq: "home.png" }) {
      childImageSharp {
        gatsbyImageData
      }
    }
    smallStepsText: file(relativePath: { eq: "home.json" }) {
      childTextContentJson {
        smallSteps {
          description
          heading
        }
        joinUs {
          heading
          description
        }
      }
    }
    patientStoriesText: file(relativePath: { eq: "home.json" }) {
      childTextContentJson {
        patientStories {
          heading
          topStory {
            description
            heading
            videoLink
          }
          stories {
            description
            heading
            videoLink
            image
          }
        }
      }
    }
    patientStoriesTopImage: file(relativePath: { eq: "top-story.png" }) {
      childImageSharp {
        gatsbyImageData
      }
    }
    patientStoriesImages: allFile(
      filter: { relativeDirectory: { eq: "patient-stories" } }
    ) {
      nodes {
        name
        childImageSharp {
          gatsbyImageData
        }
      }
    }
    overviewText: file(relativePath: { eq: "home.json" }) {
      childTextContentJson {
        overview {
          heading
          description
        }
      }
    }
    overviewImages: allFile(
      filter: { relativePath: { regex: "/overview/" } }
      sort: { fields: name, order: ASC }
    ) {
      nodes {
        childImageSharp {
          gatsbyImageData
        }
      }
    }
  }
`;
